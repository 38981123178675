b{
  color: #212121;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.logo{
  width: 28px;
  height: 28px;
  position: absolute;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-panel {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 20px;
}

.App-link {
  color: #61dafb;
}

.header {
  color: #666666;
  font-size: 36px;
}

.App-title {
  font-size: 36px;
  color: #212121;
  text-align: center;
}

.App-title b{
  display: block;
  font-size: 16px;
  color: #8e8e8e;
}

.heading{
  font-size: 24px;
  color: #212121;
}

.regular-text{
  font-size: 18px;
  font-family: "Gotham Rounded"
}

.textField{
  margin: 10px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

hr{
  height: 0px;
  border: none;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-bottom: 1px solid #E8EDF2;
  width: 100%;
  margin: 20px 0;
}

a{
  text-decoration: none;
  color: #10A5E8;
}

.pull-right{
  float:right;
}

.text-right{
  text-align: right;
}

ul li{
  list-style: none;
  margin-bottom: 10px;
}

ul li a{
  color: #8e8e8e;
  font-weight: normal;
}

ul li a:hover{
  color: #3c4859;
}

.jobRow{
  cursor: pointer;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: #8e8e8e;
  min-height: 150px
}

.jobRow:hover{
  background-color: #ecf0f1;
}

.jobRowTitle{
  font-size: 24px;
  color: rgb(0, 177, 106);
}

.jobPanel{
  padding: 0 !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}



@font-face {
  font-family: 'GothamRounded-Book';
  src: url(../../public/fonts/GothamRounded-Book.otf) format('opentype') ;
}

@font-face {
  font-family: 'GothamRounded-Bold';
  src: url(../../public/fonts/GothamRounded-Bold.otf) format('opentype') ;
}


#cta{
   display: grid;
   height: 80vh;
   left:0;
   top:10px;
   background: #000000;
   background: url(../../public/freelancers.jpg);
   background-repeat: no-repeat;
   background-size: 100% auto;
}

#cta-overlay{
   display: grid;
   height: 80vh;
   left:0;
   top:10px;
   background: rgba(0,0,0,0.5);
 
}#cta-content{
  
}

#cta-content h1{
   padding: 5%;
   text-align: left;
   font-size: 72px;
   color: #fff;
   font-family: "GothamRounded-Bold" !important;
   text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.31);
}

#cta-btn-container{
  text-align: right;
}
#cta-btn{
   /*margin-top: 50%;*/
}

#cta-btn .MuiButton-label{
  font-size: 42px;
}

#hero{
   position: relative;
   display: grid;
   height: 100vh;
   max-height: 900px;
   width: 100%;
   left:0;
   top:20px;
   background: #000000;
}



#hero video, #hero img{
  opacity: 0.6;
  max-height: 900px;
}

#hero-content {
  z-index: 1;
  margin-top: 10%;
  margin-left: 5%;
  margin-right: 5%;
}

#hero-content p{
  text-align: left;
  color:#fff;
   padding: 0;
  margin: 0;
}


#hero-content p h1{
  padding: 0;
  margin: 0;
  font-size: 64px;
  color: #fff;
   font-family: "GothamRounded-Bold" !important;
   text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.31);
}

#hero-content p h1 span{
  padding: 0;
  margin: 0;
  font-size: 84px;
  color: rgb(0, 177, 106);
   font-family: "GothamRounded-Bold" !important;
   text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.31);
}


video.fullscreen, img.fullscreen {
  position: absolute;
  z-index: 0;
  object-fit: cover;
  width:100%;
  height:100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);


}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

body,html, p, em, time, li, h1, h2, h3, h4, h5 {
  font-family: 'GothamRounded-Book' !important;
  color: #666666;
  font-size: 18px;
}

.MuiListItemText-primary{
  color:#666666;
}

.MuiAlert-message{
  font-family: 'GothamRounded-Book' !important;
  font-size: 18px;
}

.MuiListItemText-secondary{
  font-size: 14px !important;
}

.MuiFormControl-marginNormal{
  margin-top: 8px !important;
}

.MuiTypography-colorTextSecondary{
  font-family: 'GothamRounded-Book' !important;
  font-style: italic !important;
}

a, a:hover{
  text-decoration: none !important;
}

button, span{
  font-family: 'GothamRounded-Book' !important;
  font-size: 18px; 
}
input, textarea, input::placeholder{
  font-family: 'GothamRounded-Book' !important;
}

h1{
  font-size: 36px;
  color: #212121;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-panel{
  border-radius: 10px;
  padding: 30px !important;
}


.portfolio-thumbnail{
  width: 100%;
  height: 300px;
  min-height: 300px;
  background-color: rgba(0, 0, 0, 0.12);
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center center;
}

.footer{
  padding: 5%;
  min-height: 200px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.public-section{
  padding-top: 10%;
}

.public-portfolio-container{
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.public-portfolio-body{
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  min-height: 300px;
  display: flex;
  text-align: center;
}

.public-portfolio-body:hover div{
  display: flex;
  opacity: 1;
}

.public-portfolio-body div{

  background-color: rgba(54,61,70,.9);
  color: #fff;
  height: 300px;
  width: 100%;
  display: flex;
  justify-content:center;
  align-items:center;
  transition: opacity 0.2s ease-out;
  opacity: 0; 
}

.public-portfolio-footer{
  padding: 10px;
}

.list-box{
  background-color: #0D151E;
  border-radius: 5px;
  padding:0;
}

.list-box li{
  list-style: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
}

.center{
  text-align: center;
}

.card{
  border-radius: 30px !important;
  overflow: hidden;
}

.alert-success{
  padding: 25px;
  border-radius: 5px;
  color: #3c763d;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-warning{
  padding: 25px;
  border-radius: 5px;
  color: rgb(102, 60, 0);
  background-color: rgb(255, 244, 229);
  border-color: #c3e6cb;
}

.logo{
  width: 28px;
  height: auto;
}

.chat-panel{
  padding: 0 !important;
}

.chat-body{
  border-left: 1px solid #cccccc;
}

.chat-body-title{
  text-align: center;
}

.chat-body-inner{
  min-height: 280px;
  height: 280px;
  max-height: 280px;
}

.chat-input{
  margin-bottom: 10px;
}
.hero{
  background: url(../../public/hero-right.png);
  /*background-size: cover;*/
  background-position: center right;
  background-repeat: no-repeat;
  min-height: 400px;
  padding-top: 15% !important;
  width: 80%;
  left: 0;
  right: 0;
  margin: auto auto;
}

.hero h1{
  color: #3c4859;
  font-size: 48px;
  
}

.heroCaption span{
  color: #00b16a;
  font-size: 72px; 
}

.hero div{
  padding-bottom: 10%;
}


.heroCaption{
  color: #3c4859;
  font-size: 72px;
}

.buttonPrimary {
    padding: 18px;
    font-family: 'GothamRounded-Book';
    background-color: '#00b16a';
   }

.App{
  padding: 5%;
}
.App-left{
  text-align: left !important;
}

.bigAvatar{
  width: 100px;
  height: 100px;
}

.logo{
  width: 35px !important;
  height: auto !important;
}

#cookieChoiceInfo{
  position: fixed;
  width: 100%;
  background-color: #3c4859;
  color: #fff;
  bottom: 0;
  padding: 1.5%;
  text-align: center;
  font-size: 16px;
  z-index: 2;
}

.logo-standard{
  width: 120px;
  height: auto;
}

.comingsoon{
  background: url(../../public/comingsoon.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100%;
  height: 100%;
  width: 100%;
}

.comingsoon .panel{

  padding: 5%;
  border-radius: 1%;
  margin: 5%;
}

.comingsoon center{
  color: #fff;
}

.comingsoon input{
  background-color: #fff;
  overflow: hidden;
  border-radius: 5px;
}

#root{
  height: 100%;
}

.page-container{
  padding-top: 4%;
}

.chat-bubble{
  color: #000;
  background-color: #F0F0F0;
  padding: 10px;
  display: inline-block;
  border-radius: 5px;
}

.chat-bubble-invert{
  color: #000;
  background-color: #b6fadf;
  padding: 10px;
  display: inline-block;
  border-radius: 5px;
}

.messagesList .MuiTypography-colorTextSecondary{
 text-align: right;

}
.chat-body-inner
{
  max-height: 500px;
  overflow-y: auto;
}

.contact-list:hover{
  background-color: #F0F0F0;
  cursor: pointer;
}

.emoji-container{
  position: absolute;
  background-color: #fff;
  bottom: -3%;
}

.chat-image{
  width: 150px;
  height: auto;
}

.badge{
  display: inline-block;
  width: 15px;
  height: 15px;
  text-align: center;
  border-radius: 15px;
  padding: 6px;
  line-height: 15px;
  background-color: #00b16a !important;
  color: #fff;
}

.MuiBadge-badge.MuiBadge-colorPrimary{
  background-color: #00b16a !important;
}

.MuiPaper-elevation2{
  box-shadow: none !important;
}

.buttonbase{
  border: 5px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  transition: border 0.5s;
  cursor: pointer;
}
.buttonbase:hover{
  border: 5px solid rgb(0, 177, 106);
  border-radius: 10px;
}

.buttonbase img{
  width: auto;
  height: 150px;
}

.pull-right{
  float: right;
}

.gradientBox{
  background:linear-gradient(rgba(250,0,0,0.5),transparent);
  background-color: transparent;
}

.status-danger{
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  background-color: #e74c3c;
}

.status-success{
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  background-color: rgb(0, 177, 106);
}

.landing-job-categories{
  width: 30%;
}

.logo-lg{
  width: 200px;
  height: auto;
}

.filepond--credits{
  display: none;
}

.ais-Hits-list{
  padding: 0;
  margin: 0;
}


.ais-Hits-list li:last-child .jobRow {
   border-bottom: 0px;
}

.ais-Hits-list li {
   margin-bottom: 0px;
}

.jobPanel{
  overflow: hidden;
}

.pagination{
  text-align: center;
}

.pagination li{
  margin-left: 5px;
  margin-right: 5px;
}

.pagination li, .pagination li a{
  cursor: pointer;
  padding: 10px;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  display: inline-block;

}

.pagination li a:hover{
  background-color: rgb(0, 177, 106, 0.5);
  color: #fff;

}

.pagination li:hover a{
  color: #fff;
}

.ais-Pagination-link.ais-Pagination-link--selected
{
   background-color: rgb(0, 177, 106);
  color: #fff;
}

.ais-RefinementList-list{
  padding: 0px;
}

.ais-RefinementList-checkbox{


}

.ais-RefinementList-label{
  width: 90%;
}

.ais-RefinementList-count{
  display: none;
}

.catPanel{
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px;
  border-radius: 10px;
}

.green-paper{
  background-color: rgb(0, 177, 106) !important;
  margin-left: 2%;
  margin-right: 2%;
  padding: 2%;
}

.green-paper h2{
  color: #fff;
  font-size: 24px !important;
}

.green-paper p{
  color: #fff;
}

.default-paper{
  margin-left: 2%;
  margin-right: 2%;
  padding: 2%;
}

.default-paper h2{
  color: rgb(0,177,106);
  font-size: 24px !important;
}


.default-paper p{
  color: rgb(0,177,106);
}

#mobilenav{
  display: none;
}


@media all and (max-width: 480px) {
  
  #funded-text{
      font-size: 36px;
      text-align: center;
  }

  #cta{
     display: grid;
     height: 70vh;
     left:0;
     top:10px;
     background: #000000;
     background: url(../../public/freelancers.jpg);
     background-repeat: no-repeat;
     background-size: cover;
  }

  #cta-overlay{
     display: grid;
     height: 70vh;
     left:0;
     top:10px;
     background: rgba(0,0,0,0.5);
   
  }#cta-content{
    
  }

  #cta-content h1{
    /* margin-top: 20%;*/
     text-align: left;
     font-size: 42px;
     color: #fff;
     font-family: "GothamRounded-Bold" !important;
  }

  #cta-btn-container{
    text-align: center;
  }
  #cta-btn{
    /* margin-top: 20%;*/
  }

  #cta-btn .MuiButton-label{
    font-size: 42px;
  }
    
  #hero{
   position: relative;
   display: grid;
   height: 80vh;
   width: 100%;
   left:0;
   top:10px;
   background: #000000;
}

  #hero-content p{
    text-align: left;
    color:#fff;
     padding: 0;
    margin: 0;
    margin-top: 15%;
  }


  #hero-content p h1{
    padding: 0;
    margin: 0;
    font-size: 40px;
    color: #fff;
     font-family: "GothamRounded-Bold" !important;
  }

  #hero-content p h1 span{
    padding: 0;
    margin: 0;
    font-size: 40px;
    color: rgb(0, 177, 106);
     font-family: "GothamRounded-Bold" !important;
  }


  #webnav{
    display: none;
  }

  #mobilenav{
    display: block;
  }

  .profilePic{
    position: initial !important;
    text-align: center;
    margin-left: 20% !important;
  }

  .badge-container{
    text-align: center;
      margin-top: 0 !important;
  }

  .user-info{
    text-align: center;
  }
}


#notification-panel{
  position: absolute;
  right: 5px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  max-height: 80vh;
  overflow-y:auto;
  overflow-x: hidden;
  padding-bottom: 10px;
  box-shadow: -1px 10px 42px 0px rgba(0,0,0,0.21);
  -webkit-box-shadow: -1px 10px 42px 0px rgba(0,0,0,0.21);
  -moz-box-shadow: -1px 10px 42px 0px rgba(0,0,0,0.21);
  display: flex;
}

.profilePic{
    position: absolute;
    margin-top: -70px;
    margin-left: 30px;
}


.badge-container{
  margin-top: 50px;
}


.adminFreelancerList{
  width:100%;
  overflow-x: scroll;
}



ins.adsbygoogle{
  display: block;
}

ins.adsbygoogle[data-ad-status="unfilled"] #aswift_1_host{
   display: none !important;
}

ins.adsbygoogle[data-ad-status="filled"] .adplaceholder{
   display: none !important;
}

ins.adsbygoogle[data-ad-status="unfilled"]{
   display: none !important;
}

.adsBox{
  width: 200px !important;
  height: 200px !important;
  min-height: 200px !important;
  min-width: 200px !important;
  max-height: 200px !important;
  max-width: 200px !important;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
}

#downshift-1-menu {
  overflow-y: scroll; /* Add this line to enable a scrollbar when the input content exceeds its height */
  max-height: 150px; /* Set a maximum height for the input */
}

.DraftEditor-editorContainer {
    height: 250px;
    max-height: 250px; /* Set your desired max height */
    overflow-y: auto; /* Add scrollbar if content overflows */
}

.rdw-editor-wrapper{
  padding: 5px;
  border: 1px solid #CCC;
  border-radius: 5px;
}

.App-panel ul li{
    list-style-type: disc; /* or circle, square */

}


h2 ins, h2 span{
  color: rgb(0, 177, 106) !important;
}

#search-container{
  background-color: rgba(255,255,255,0.2);
  padding: 10px;
  border-radius: 10px;
}

#hero-title{
  color:#fff;
}




@media only screen and (max-width: 414px) {
  /* Styles specific to iPhone 6 Plus, 6s Plus, 7 Plus, or 8 Plus */

    #hero
    { 
       height: 85vh;
       max-height: 1000px;
    }
  
}

@media only screen and (max-width: 375px) {
  /* Styles specific to iPhone 6, 6s, 7, or 8 */

    #hero
    { 
       height: 85vh;
       max-height: 1000px;
    }
}

@media only screen and (max-width: 414px) {
  /* Styles specific to iPhone 6 Plus, 6s Plus, 7 Plus, or 8 Plus */

    #hero
    { 
       height: 85vh;
       max-height: 1000px;
    }
}

@media only screen and (max-width: 375px) and (max-height: 812px) {
  /* Styles specific to iPhone X, XS, or 11 Pro */

    #hero
    { 
       height: 85vh;
       max-height: 1000px;
    }
}

@media only screen and (max-width: 414px) and (max-height: 896px) {
  /* Styles specific to iPhone XR or 11 */

    #hero
    { 
       height: 85vh;
       max-height: 1000px;
    }
}

@media only screen and (max-width: 414px) and (max-height: 896px) {
  /* Styles specific to iPhone XS Max or 11 Pro Max */

    #hero
    { 
       height: 95vh;
       max-height: 1000px;
    }
}


#blog-container li{
  list-style: initial;
}

#blog-container h1, 
#blog-container h2{
  color: #00b16a;
}

#blog-container p, #blog-container li, #blog-container b{
  color: #000;
}

#blog-container h1{
  font-size: 36px;
}

#blog-container h2{
  font-size: 28px;
}

#blog-container h3{
  font-size: 20px;
  color: #000;
}

#blog-container a{
  color: #10A5E8 !important;
}

